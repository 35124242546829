




import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class AppEnv extends Vue {
  // Props
  @Prop({ required: true, type: String })
  public readonly pipeline!: string

  // Getters
  private get isDisplay(): boolean {
    return this.pipeline !== 'production'
  }
}
