import ListSearch from './list-search'

import { API } from '~/utils/constants'

export default {
  orderOpenReferences: {
    namespaced: true,
    state: () => ({
      ...ListSearch.state,
      endpoint: API.GET_ORDER_OPEN_REFERENCES,
    }),
    getters: { ...ListSearch.getters },
    actions: { ...ListSearch.actions },
    mutations: { ...ListSearch.mutations },
  },
  orderOpenRetails: {
    namespaced: true,
    state: () => ({
      ...ListSearch.state,
      endpoint: API.GET_ORDER_OPEN_PDV,
    }),
    getters: { ...ListSearch.getters },
    actions: { ...ListSearch.actions },
    mutations: { ...ListSearch.mutations },
  },
  orderOpenLinesSend: {
    namespaced: true,
    state: () => ({
      ...ListSearch.state,
      endpoint: API.GET_ORDER_OPEN_LINES_SEND,
    }),
    getters: { ...ListSearch.getters },
    actions: { ...ListSearch.actions },
    mutations: { ...ListSearch.mutations },
  },
  orderOpenLinesSent: {
    namespaced: true,
    state: () => ({
      ...ListSearch.state,
      endpoint: API.GET_ORDER_OPEN_LINES_SENT,
    }),
    getters: { ...ListSearch.getters },
    actions: { ...ListSearch.actions },
    mutations: { ...ListSearch.mutations },
  },
  orderOpenLinesDeleted: {
    namespaced: true,
    state: () => ({
      ...ListSearch.state,
      endpoint: API.GET_ORDER_OPEN_LINES_DELETED,
    }),
    getters: { ...ListSearch.getters },
    actions: { ...ListSearch.actions },
    mutations: { ...ListSearch.mutations },
  },
  orderOpenDetails: {
    namespaced: true,
    state: () => ({
      ...ListSearch.state,
      endpoint: API.GET_ORDER_OPEN_LINES_SENT,
    }),
    getters: { ...ListSearch.getters },
    actions: { ...ListSearch.actions },
    mutations: { ...ListSearch.mutations },
  },
  orderOpen: {
    namespaced: true,
    state: () => ({
      ...ListSearch.state,
      endpoint: API.GET_ORDER_OPEN_REFERENCES,
    }),
    getters: { ...ListSearch.getters },
    actions: { ...ListSearch.actions },
    mutations: { ...ListSearch.mutations },
  },
}
