const state = {
  role: '',
}

const getters = {
  role: (state) => state.role,
}

const actions = {
  // updateRole: ({ commit }, role) => commit('setRole', role),
}

const mutations = {
  setAuthRole(state, role): void {
    state.role = role
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
