import axios from 'axios'

import { API } from '~/utils/constants'

// MUTATIONS TYPE
export const INIT_STORE = 'Init store'
export const SET_NOTIFICATIONS = 'Set notifications'
export const ADD_NOTIFICATIONS = 'Add notifications'
export const SET_UNREAD = 'Set unread'
export const SET_UNREAD_ALL = 'Set unread all'
export const SET_COUNT = 'Set count'
export const REMOVE_STORE = 'Remove store'
export const RESET_STORE = 'Reset store'

export const namespaced = true

type NotificationState = {
  notifications: NotificationDetail[]
  more: boolean
  unread: number
  lastId: ID
}

export const state = (): NotificationState => ({
  notifications: [],
  more: false,
  unread: 0,
  lastId: '',
})

export const mutations = {
  [INIT_STORE](state: NotificationState, data): void {
    state.notifications = data.results
    state.lastId = data.results.at(-1).id
    state.more = data.hasMore
    state.unread = data.unreadCount
  },
  [SET_NOTIFICATIONS](state: NotificationState, data): void {
    state.notifications = [
      ...data.results.filter(
        (n) => !state.notifications.find((s) => s.id === n.id),
      ),
      ...state.notifications,
    ]
    state.unread = data.unreadCount
  },
  [ADD_NOTIFICATIONS](state: NotificationState, data): void {
    state.notifications.push(...data.results)
    state.lastId = data.results.at(-1).id
    state.more = data.hasMore
    state.unread = data.unreadCount
  },
  [SET_UNREAD](state: NotificationState, data): void {
    state.unread = data.count
    state.notifications = state.notifications.map((n) => {
      if (n.id === data.id) {
        return {
          ...n,
          readAt: new Date().toISOString(),
        }
      }

      return n
    })
  },
  [SET_UNREAD_ALL](state: NotificationState, count: number): void {
    state.unread = count
    state.notifications = state.notifications.map((n) => {
      if (!n.readAt) {
        return {
          ...n,
          readAt: new Date().toISOString(),
        }
      }

      return n
    })
  },
  [SET_COUNT](state: NotificationState, count): void {
    state.unread = count
  },
  [REMOVE_STORE](state: NotificationState): void {
    state.notifications = []
    state.more = false
    state.lastId = ''
    // Keep unread data
    // state.unread = 0
  },
  [RESET_STORE](state: NotificationState): void {
    state.notifications = []
    state.more = false
    state.lastId = ''
    state.unread = 0
  },
}

export const actions = {
  async fetchNotifications({ commit }): Promise<void> {
    try {
      const { data } = await axios.get(API.GET__NOTIFICATION_LAST)

      commit(INIT_STORE, data)
    } catch (err) {}
  },
  async fetchUnreadCount({ commit }): Promise<void> {
    try {
      const { data } = await axios.get(API.GET__NOTIFICATION_COUNT)

      commit(SET_COUNT, data.unreadCount)
    } catch (err) {}
  },
  async loadNewerNotifications({
    commit,
    state,
    dispatch,
  }: {
    commit: any
    state: NotificationState
    dispatch: any
  }): Promise<void> {
    if (!state.notifications.length) {
      await dispatch('fetchNotifications')

      return
    }

    try {
      const { data } = await axios.get(API.GET__NOTIFICATION_LAST)

      commit(SET_NOTIFICATIONS, data)
    } catch (err) {}
  },
  async loadMoreNotifications({
    commit,
    state,
  }: {
    commit: any
    state: NotificationState
  }): Promise<void> {
    try {
      const { data } = await axios.get(
        `${API.GET__NOTIFICATION_LAST}${
          state.lastId ? `?id=${state.lastId}` : ''
        }`,
      )

      commit(ADD_NOTIFICATIONS, data)
    } catch (err) {}
  },
  async readNotification(
    { commit },
    notification: NotificationDetail,
  ): Promise<void> {
    if (notification.readAt === null) {
      try {
        const { data } = await axios.put(
          API.PUT__NOTIFICATION_READ.replace(
            '{nid}',
            notification.id.toString(),
          ),
        )

        commit(SET_UNREAD, {
          id: notification.id,
          count: data.unreadCount,
        })
      } catch (err) {}
    }
  },
  async readAllNotifications({ commit }): Promise<void> {
    try {
      const { data } = await axios.put(API.PUT__NOTIFICATION_READ_ALL)

      commit(SET_UNREAD_ALL, data.unreadCount)
    } catch (err) {}
  },
  removeStoredNotifications({ commit }): void {
    commit(REMOVE_STORE)
  },
  resetNotifications({ commit }): void {
    commit(RESET_STORE)
  },
}

export const getters = {}

export default {
  namespaced,
  state,
  mutations,
  actions,
  getters,
}
