




import { PropType } from 'vue'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class AppAuth extends Vue {
  @Prop({ required: true, type: String as PropType<Role> })
  public readonly role!: Role

  public created(): void {
    this.$store.commit('setAuthRole', this.role)
  }
}
