import {
  displayedName,
  extractDate,
  findStatus,
  formatDate,
  formatDatetime,
  formatHour,
  typeName,
} from './helpers'

export const pluralize = (words: string, amount: number): string => {
  return amount > 1 ? `${words.split(' ').join('s ')}s` : words
}

export const capitalize = (value): string => {
  if (!value) return ''

  value = value.toString()

  return value.charAt(0).toUpperCase() + value.slice(1)
}

export const uppercase = (value: string): string => value.toUpperCase()

export const upper = (value: string): string => uppercase(value)

export const nullable = (value: string): string =>
  !value || value === '0%' || value === '%' ? '-' : value

export const readMore = (
  text: string,
  length: number,
  suffix: string = '...',
): string => {
  if (text.length <= length + suffix.length) {
    return text
  } else {
    return text.substring(0, length) + suffix
  }
}

export const humanFileSize = (value: number): string => {
  const thresh = 1024
  const dp = 1
  let bytes: number = value

  if (Math.abs(bytes) < thresh) {
    return `${bytes} octets`
  }

  const units: string[] = ['Ko', 'Mo', 'Go']
  let u: number = -1
  const r: number = 10 ** dp

  do {
    bytes /= thresh
    ++u
  } while (
    Math.round(Math.abs(bytes) * r) / r >= thresh &&
    u < units.length - 1
  )

  return bytes.toFixed(dp) + units[u]
}

export const fileExtension = (value: string): string => {
  const ext: string = value.split('.').reverse()[0]

  if (ext) {
    return ext.toUpperCase()
  }

  return value
}

export const datetime = (
  value: string,
  formatStr = "dd/MM/yyyy HH'H'mm",
): string => formatDatetime(value, formatStr)

export const date = (value: string, formatStr = 'dd/MM/yyyy'): string =>
  formatDate(value, formatStr)

export const hour = (value: string, formatStr = "HH'H'mm"): string =>
  formatHour(value, formatStr)

export const dateToString = (value: string | Date): string => extractDate(value)

export const address = (obj): string => {
  return [
    obj.address,
    obj.address2,
    obj.address3,
    obj.zipcode,
    obj.city,
    obj.country,
  ]
    .filter((e): boolean => e !== null && e !== undefined)
    .join(', ')
}

export const refStatus = (
  status: string,
  type: 'global' | 'step' | 'name' = 'name',
): string => findStatus(status, type)

export const fileStatus = (
  status: string,
  type: 'global' | 'step' | 'name' = 'name',
): string => findStatus(status, type)

export const status = (
  status: string,
  type: 'global' | 'step' | 'name' = 'name',
): string => findStatus(status, type)

export const displayWarning = (warnings): string => {
  let warningMerge: string = ''

  warnings.forEach((warning): void => {
    warningMerge += warning.text + ' '
  })

  return warningMerge
}

export { displayedName, typeName }
