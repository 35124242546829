var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "button",
    {
      staticClass: "m-notif u-ml-auto u-mr-1",
      class: { "is-active": _vm.unread > 0 },
      on: { click: _vm.openNotifications },
    },
    [
      _vm.unread
        ? _c("span", { staticClass: "m-notif__counter" }, [
            _vm._v(_vm._s(_vm.unread)),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }