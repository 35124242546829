import ListSearch from './list-search'

import { API } from '~/utils/constants'

const state = () => ({
  ...ListSearch.state,
  endpoint: API.GET_PDV_ESP_POSITIONS,
})

const getters = {
  ...ListSearch.getters,
}

const actions = {
  ...ListSearch.actions,
}

const mutations = {
  ...ListSearch.mutations,
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
