import pkgInfo from '../package.json'

// Dependencies
import Vue from 'vue'
import Vuex from 'vuex'
import VueCompositionAPI from '@vue/composition-api'
import VueSimpleSuggest from 'vue-simple-suggest/lib'
import VueViewer from 'v-viewer'
import VCalendar from 'v-calendar/lib/components/date-picker.umd'
import VModal from 'vue-js-modal'
import Toasted from 'vue-toasted'
import frag from 'vue-frag'
import InlineSvg from 'vue-inline-svg'
import FloatingVue from 'floating-vue'
import InfiniteLoading from 'vue-infinite-loading'
import Axios, { type AxiosStatic } from 'axios'
import * as Sentry from '@sentry/vue'
import { BrowserTracing } from '@sentry/tracing'

// Required styles form dependencies packages
import 'viewerjs/dist/viewer.css'
import 'floating-vue/dist/style.css'

// Internal styles
// import './sass/_old/app.scss'
import '@/sass/index.scss'

// Init Vuex store before start to define components
import store from '~/store'

// Custom components
import '~/components'

// Axios
Vue.prototype.$axios = Axios
declare module 'vue/types/vue' {
  interface Vue {
    $axios: AxiosStatic
    $viewer: any
  }
}

// Plugins
Vue.use(VueCompositionAPI)
Vue.use(VueViewer)
Vue.use(Vuex)
Vue.use(VModal)
Vue.use(Toasted, {
  position: 'bottom-right',
  duration: 5000,
  keepOnHover: true,
  className: 'c-toast',
  containerClass: 'c-toasts',
  theme: 'none',
  action: {
    class: 'close h-stretched-link',
    onClick: (e, toastObject) => {
      toastObject.goAway(0)
    },
  },
})
Vue.use(FloatingVue)
Vue.use(InfiniteLoading)

// Components
Vue.component('VueSimpleSuggest', VueSimpleSuggest)
Vue.component('VDatePicker', VCalendar)
Vue.component('InlineSvg', InlineSvg)
Vue.component('InfiniteLoading', InfiniteLoading)

// Directives
Vue.directive('frag', frag)

// Custom filters
import * as FiltersUtils from './js/utils/filters'

Object.keys(FiltersUtils).forEach((f) => {
  Vue.filter(f, FiltersUtils[f])
})

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    Vue,
    dsn: 'https://6354aefe466e470e8c16cf46de55fac0@o985193.ingest.sentry.io/5941349',
    release: pkgInfo.version,
    integrations: [
      new Sentry.Replay(),
      new BrowserTracing({
        // routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracePropagationTargets: ['pulse.sfr.fr'],
      }),
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 0.1,
    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,
    logErrors: true,
  })
}

// Setup
Array.prototype.forEach.call(
  document.querySelectorAll('.js-vue-app'),
  (el: Element): void => {
    new Vue({
      el,
      store,
    })
  },
)

if (document.querySelector('#app')) {
  new Vue({
    el: '#app',
    store,
    delimiters: ['${', '}'],
  })
}
