import Vue from 'vue'

//=============================================================================
// App (Auth & Env)
//=============================================================================
import AppAuth from '~/components/app/AppAuth.vue'
import AppEnv from '~/components/app/AppEnv.vue'
import AppNotification from '~/components/app/AppNotification.vue'

Vue.component('AppAuth', AppAuth)
Vue.component('AppEnv', AppEnv)
Vue.component('AppNotification', AppNotification)

//=============================================================================
// LIST
//=============================================================================
Vue.component('AreasList', () => import('~/components/lists/AreasList.vue'))
Vue.component(
  'BatFilesList',
  () => import('~/components/lists/BatFilesList.vue'),
)
Vue.component(
  'CampaignReferencesList',
  () => import('~/components/lists/CampaignReferencesList.vue'),
)
Vue.component(
  'CampaignPositionsList',
  () => import('~/components/lists/CampaignPositionsList.vue'),
)
Vue.component(
  'CampaignsList',
  () => import('~/components/lists/CampaignsList.vue'),
)
Vue.component(
  'DashboardCampaignsList',
  () => import('~/components/lists/DashboardCampaignsList.vue'),
)
Vue.component(
  'DashboardPositionsList',
  () => import('~/components/lists/DashboardPositionsList.vue'),
)
Vue.component('FormatsList', () => import('~/components/lists/FormatsList.vue'))
Vue.component('HdFilesList', () => import('~/components/lists/HdFilesList.vue'))
Vue.component('UsersList', () => import('~/components/lists/UsersList.vue'))
Vue.component(
  'CompaniesList',
  () => import('~/components/lists/CompaniesList.vue'),
)
Vue.component(
  'PositionsList',
  () => import('~/components/lists/PositionsList.vue'),
)
Vue.component('EspList', () => import('~/components/lists/EspList.vue'))
Vue.component(
  'EspPositionsList',
  () => import('~/components/lists/EspPositionsList.vue'),
)
Vue.component('GeList', () => import('~/components/lists/GeList.vue'))
Vue.component('VadList', () => import('~/components/lists/VadList.vue'))
Vue.component('InsList', () => import('~/components/lists/InsList.vue'))
Vue.component(
  'ReferencesList',
  () => import('~/components/lists/ReferencesList.vue'),
)
Vue.component(
  'RestockingList',
  () => import('~/components/lists/RestockingList.vue'),
)
Vue.component(
  'TargetingsList',
  () => import('~/components/lists/TargetingsList.vue'),
)
Vue.component(
  'OrderPushProvisionalList',
  () => import('~/components/lists/OrderPushProvisionalList.vue'),
)
Vue.component(
  'OrderPushReferencesList',
  () => import('~/components/lists/OrderPushReferencesList.vue'),
)
Vue.component(
  'OrderPushRetailsList',
  () => import('~/components/lists/OrderPushRetailsList.vue'),
)
Vue.component(
  'OrderPushLinesDeletedList',
  () => import('~/components/lists/OrderPushLinesDeletedList.vue'),
)
Vue.component(
  'OrderPushLinesSendList',
  () => import('~/components/lists/OrderPushLinesSendList.vue'),
)
Vue.component(
  'OrderPushLinesSentList',
  () => import('~/components/lists/OrderPushLinesSentList.vue'),
)
Vue.component(
  'OrderOpenReferencesList',
  () => import('~/components/lists/OrderOpenReferencesList.vue'),
)
Vue.component(
  'OrderOpenRetailsList',
  () => import('~/components/lists/OrderOpenRetailsList.vue'),
)
Vue.component(
  'OrderOpenLinesDeletedList',
  () => import('~/components/lists/OrderOpenLinesDeletedList.vue'),
)
Vue.component(
  'OrderOpenLinesSendList',
  () => import('~/components/lists/OrderOpenLinesSendList.vue'),
)
Vue.component(
  'OrderOpenLinesSentList',
  () => import('~/components/lists/OrderOpenLinesSentList.vue'),
)
Vue.component('VideosList', () => import('~/components/lists/VideosList.vue'))

//=============================================================================
// LIST FEATURES
//=============================================================================
Vue.component('Filters', () => import('~/components/search/Filters.vue'))
Vue.component('SearchBar', () => import('~/components/search/SearchBar.vue'))
Vue.component(
  'LogisticSearchBar',
  () => import('~/components/search/LogisticSearchBar.vue'),
)
Vue.component(
  'Pagination',
  () => import('~/components/pagination/Pagination.vue'),
)

//=============================================================================
// FORMS
//=============================================================================
Vue.component('AreaForm', () => import('~/components/area/AreaForm.vue'))
Vue.component('BulkForm', () => import('~/components/form/BulkForm.vue'))
Vue.component(
  'CampaignForm',
  () => import('~/components/campaign/CampaignForm.vue'),
)
Vue.component(
  'CampaignDuplicateForm',
  () => import('~/components/campaign/CampaignDuplicateForm.vue'),
)
Vue.component(
  'CompanyForm',
  () => import('~/components/company/CompanyForm.vue'),
)
Vue.component('FormatForm', () => import('~/components/format/FormatForm.vue'))
Vue.component('ImportForm', () => import('~/components/form/ImportForm.vue'))
Vue.component('KitForm', () => import('~/components/kit/KitForm.vue'))
Vue.component(
  'OrderOpenForm',
  () => import('~/components/order/OrderOpenForm.vue'),
)
Vue.component(
  'OrderPushForm',
  () => import('~/components/order/OrderPushForm.vue'),
)
Vue.component(
  'PositionForm',
  () => import('~/components/position/PositionForm.vue'),
)
Vue.component(
  'ProfileForm',
  () => import('~/components/profile/ProfileForm.vue'),
)
Vue.component(
  'ReferenceForm',
  () => import('~/components/reference/ReferenceForm.vue'),
)
Vue.component(
  'ReferenceReuseSearch',
  () => import('~/components/reference/ReferenceReuseSearch.vue'),
)
Vue.component(
  'ReferenceGlobalForm',
  () => import('~/components/reference/ReferenceGlobalForm.vue'),
)
Vue.component(
  'RetailAreaForm',
  () => import('~/components/retail/RetailAreaForm.vue'),
)
Vue.component(
  'RetailInfosForm',
  () => import('~/components/retail/RetailInfosForm.vue'),
)
Vue.component(
  'TargetingStaticForm',
  () => import('~/components/targeting/TargetingStaticForm.vue'),
)
Vue.component(
  'TargetingDynamicForm',
  () => import('~/components/targeting/TargetingDynamicForm.vue'),
)
Vue.component('UserForm', () => import('~/components/user/UserForm.vue'))
Vue.component('VideoForm', () => import('~/components/video/VideoForm.vue'))

//=============================================================================
// COMMONS
//=============================================================================
Vue.component('BookAction', () => import('~/components/book/BookAction.vue'))
Vue.component('ChartPie', () => import('~/components/chart/ChartPie.vue'))
Vue.component(
  'RetailShowcase',
  () => import('~/components/retail/RetailShowcase.vue'),
)
Vue.component('ViewerBat', () => import('~/components/viewer/ViewerBat.vue'))
Vue.component('ViewerHd', () => import('~/components/viewer/ViewerHd.vue'))
