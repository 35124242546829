import axios, { Method } from 'axios'

let abortController: AbortController

export default {
  async search(
    endpoint: string,
    method: Method = 'GET',
    initialParams: any,
    data: any,
  ): Promise<any> {
    // Force abort before run request
    if (abortController) abortController.abort()

    abortController = new AbortController()
    const signal = abortController.signal

    let sortParam: Nullable<string> = null

    if (initialParams.sort?.key) {
      sortParam = `${(initialParams.sort?.value === 'DESC' && '-') || ''}${
        initialParams.sort.key
      }`
    }

    const params = {
      ...initialParams,
      sort: sortParam,
    }

    return axios({
      url: endpoint,
      method,
      params,
      data,
      signal,
    }).then((response) => response.data)
  },
}
