export const API = {
  // COMPANY
  GET_COMPANY_LIST: '/api/entreprise',
  POST_COMPANY_CREATE: '/api/entreprise/creer',
  POST_COMPANY_EDIT: '/api/entreprise/modifier',
  DELETE_COMPANY_DELETE: '/api/entreprise/supprimer',
  // USER
  GET_USER_LIST: '/api/user',
  POST_USER_CREATE: '/api/user/creer',
  POST_USER_EDIT: '/api/user/modifier',
  DELETE_USER_DELETE: '/api/user/supprimer',
  // PROFILE:
  POST_PROFILE_EDIT: '/api/profile/modifier',
  // AREA
  GET_AREA_LIST: '/api/zone',
  GET_AREA_LIST_WITH_TARGETING: '/api/zone/targeting',
  POST_AREA_CREATE: '/api/zone/creer',
  POST_AREA_EDIT: '/api/zone/modifier',
  // POSITION - EMP
  GET_POSITION_LIST: '/api/emplacement',
  GET_POSITION_LIST_WITH_TARGETING: '/api/emplacement/targeting',
  POST_POSITION_CREATE: '/api/emplacement/creer',
  POST_POSITION_EDIT: '/api/emplacement/modifier',
  // FORMAT
  GET_FORMAT_LIST: '/api/format',
  POST_FORMAT_CREATE: '/api/format/creer',
  POST_FORMAT_EDIT: '/api/format/modifier',
  // PDV - ESP
  GET_PDV_ESP_LIST: '/api/pdv/espace-sfr',
  GET_PDV_ESP_POSITIONS: '/api/pdv/espace-sfr/{id}/emplacements',
  POST_PDV_ESP_POSITIONS: '/api/pdv/espace-sfr/{id}/emplacements/modifier',
  POST_PDV_ESP_DETAILS_EDIT:
    '/api/pdv/espace-sfr/{id}/caracteristiques/modifier',
  GET_PDV_ESP_RESTOCKING: '/api/pdv/espace-sfr/{id}/reassort',
  // PDV - GE
  GET_PDV_GE_LIST: '/api/pdv/ge',
  POST_PDV_GE_IMPORT: '/api/pdv/ge/import',
  GET_PDV_GE_RESTOCKING: '/api/pdv/ge/{id}/reassort',
  // PDV - VAD
  GET_PDV_VAD_LIST: '/api/pdv/vad',
  POST_PDV_VAD_IMPORT: '/api/pdv/vad/import',
  // PDV - INS
  GET_PDV_INS_LIST: '/api/pdv/interne',
  POST_PDV_INS_IMPORT: '/api/pdv/interne/import',
  // TARGETING
  GET_TARGETING_LIST: '/api/pdv/ciblage',
  POST_TARGETING_DYNAMIC_CREATE: '/api/pdv/ciblage/dynamic/creer',
  POST_TARGETING_DYNAMIC_EDIT: '/api/pdv/ciblage/dynamic/modifier',
  POST_TARGETING_STATIC_CREATE: '/api/pdv/ciblage/static/creer',
  POST_TARGETING_STATIC_EDIT: '/api/pdv/ciblage/static/modifier',
  POST_TARGETING_SIMULATE: '/api/pdv/ciblage/getpdvs',
  POST_TARGETING_SIMULATE_ROOT: '/api/pdv/ciblage/{id}/getpdvs',
  GET_TARGETING_EXPORT: '/api/pdv/ciblage/{id}/export',
  POST_VALIDATE_TARGETINGS: '/api/campaignInfo/validate/{id}',
  POST_REFUSE_TARGETINGS: '/api/campaignInfo/refuse/{id}',
  // REFERENCE
  GET_REFERENCE_LIST: '/api/reference',
  GET_REFERENCE_READ: '/api/reference/{rid}',
  POST_REFERENCE_EDIT: '/api/reference/modifier',
  POST_REFERENCE_REORDER: '/api/reference/{rid}/reapprovisionnement/creer',
  GET_REFERENCE_CALCULATE_QTY: '/api/reference/calculate_qty',
  GET_REFERENCE_CALCULATE_QTY2: '/api/reference/calculate_qty_v2',
  GET_REFERENCE_HOLDING: '/api/reference_holding',
  GET_REFERENCE_SEARCH: '/api/reference/searchforcopy?keyword={keyword}',
  GET_REFERENCE_REUSE:
    '/api/campagne/{cid}/reference/search_for_reuse/?keyword={keyword}',
  POST_CAMPAIGN_REFERENCES_CREATE: '/api/campagne/{cid}/reference/creer',
  POST_CAMPAIGN_REFERENCES_EDIT: '/api/campagne/{cid}/reference/modifier',
  // KIT
  GET_KIT_SEARCH: '/api/campagne/{cid}/reference/searchforkit',
  POST_CAMPAIGN_KIT_CREATE: '/api/campagne/{cid}/kit/creer',
  POST_CAMPAIGN_KIT_EDIT: '/api/campagne/{cid}/kit/modifier',
  // CAMPAIGN
  GET_CAMPAIGN_LIST: '/api/campagne',
  GET_CAMPAIGN_PASSED_LIST: '/api/campagne/passed',
  POST_CAMPAIGN_CREATE: '/api/campagne/creer',
  POST_CAMPAIGN_EDIT: '/api/campagne/modifier',
  POST_CAMPAIGN_DUPLICATE: '/api/campagne/dupliquer',
  DELETE_CAMPAIGN_DELETE: '/api/campagne/supprimer',
  GET_CAMPAIGN_REFERENCES_LIST: '/api/campagne/{cid}/reference',
  DELETE_REFERENCE_FROM_CAMPAIGN: '/api/campagne/{cid}/reference/supprimer',
  GET_CAMPAIGN_POSITIONS_LIST: '/api/campagne/{cid}/emplacement',
  GET_CAMPAIGN_HDS_LIST: '/api/campagne/{cid}/hd',
  GET_CAMPAIGN_BATS_LIST: '/api/campagne/{cid}/bat',
  GET_CAMPAIGN_BATS_BULK: '/api/campagne/{cid}/bat/bulkimport',
  // FILES
  POST_HD_FILE: '/api/hd/creer',
  POST_HD_FILE_EDIT: '/api/hd/modifier',
  POST_HD_FILE_VALIDATE: '/api/campagne/{cid}/hd/validate',
  POST_HD_FILE_REFUSE: '/api/campagne/{cid}/hd/refuse',
  POST_HD_FILE_DELETE: '/api/campagne/{cid}/hd/delete-file',
  POST_BAT_FILE: '/api/bat/creer',
  POST_BAT_FILE_EDIT: '/api/bat/modifier',
  POST_BAT_FILE_VALIDATE: '/api/campagne/{cid}/bat/validate',
  POST_BAT_FILE_REFUSE: '/api/campagne/{cid}/bat/refuse',
  POST_BAT_FILE_DELETE: '/api/campagne/{cid}/bat/delete-file',
  // WARNING
  GET_TARGETING_CONFLICT: '/api/warning/pdv_conflict/targeting',
  // ORDERS - PUSH
  POST_ORDER_PUSH: '/api/commande/push/send',
  GET_ORDER_PUSH_REFERENCES: '/api/commande/push/reference',
  GET_ORDER_PUSH_PROVISIONAL: '/api/commande/push/provisional',
  GET_ORDER_PUSH_PDV: '/api/commande/push/pdv',
  GET_ORDER_PUSH_LINES_SEND: '/api/commande/push/to-send',
  GET_ORDER_PUSH_LINES_SENT: '/api/commande/push/sent',
  GET_ORDER_PUSH_LINES_DELETED: '/api/commande/push/deleted',
  POST_ORDER_PUSH_CANCEL: '/api/commande/cancel',
  POST_ORDER_PUSH_DUPLICATE: '/api/commande/push/resend',
  POST_ORDER_PUSH_REMOVE: '/api/commande/push/delete',
  POST_ORDER_PUSH_REACTIVATE: '/api/commande/reactivate',
  // ORDERS - OPEN
  POST_ORDER_OPEN: '/api/commande/open/send',
  GET_ORDER_OPEN_REFERENCES: '/api/commande/open/reference',
  GET_ORDER_OPEN_PDV: '/api/commande/open/pdv',
  GET_ORDER_OPEN_LINES_SEND: '/api/commande/open/to-send',
  GET_ORDER_OPEN_LINES_SENT: '/api/commande/open/sent',
  GET_ORDER_OPEN_LINES_DELETED: '/api/commande/open/deleted',
  POST_ORDER_OPEN_CANCEL: '/api/commande/cancel',
  POST_ORDER_OPEN_DUPLICATE: '/api/commande/open/resend',
  POST_ORDER_OPEN_REMOVE: '/api/commande/open/delete',
  POST_ORDER_OPEN_REACTIVATE: '/api/commande/reactivate',
  // DASHBOARD
  GET_DASHBOARD_VIDEO: '/api/dashboard/videos/{vpid}',
  POST_DASHBOARD_VIDEO_DELETE: '/api/dashboard/videos/archiver',
  POST_DASHBOARD_VIDEO_RESTORE: '/api/dashboard/videos/restore',
  POST_DASHBOARD_VIDEO_SEND: '/api/dashboard/videos/envoi',
  POST_DASHBOARD_VIDEO_POSITION: '/api/dashboard/videos/position',
  GET_DASHBOARD_POSITIONS_LIST: '/api/dashboard/emplacement',
  GET_DASHBOARD_POSITIONS_PDVS: '/api/emplacement/{id}/getpdvs',
  GET_DASHBOARD_POSITIONS_PREVIOUS: '/api/emplacement/{id}/previous',

  // BOOK
  GET_BOOK_CHECK: '/book/check',
  GET_BOOK_DOWNLOAD: '/book/download',
  // NOTIFICATION
  GET__NOTIFICATION_LAST: '/api/notification/last',
  GET__NOTIFICATION_COUNT: '/api/notification/count',
  PUT__NOTIFICATION_READ: '/api/notification/{nid}/read',
  PUT__NOTIFICATION_READ_ALL: '/api/notification/read',
}

export const ROUTES = {
  // COMPANY
  COMPANY_LIST: '/entreprise',
  // USER
  USER_LIST: '/user',
  // PROFILE
  PROFILE: '/profile',
  PROFILE_EDIT: '/profile/modifier',
  // AREA
  AREA_LIST: '/zone',
  // POSITION
  POSITION_LIST: '/emplacement',
  // FORMAT
  FORMAT_LIST: '/format',
  // TARGETING
  TARGETING_LIST: '/pdv/ciblage',
  // PDV - ESPDELETE_ORDER_PUSH_REFERENCE
  PDV_ESP_DETAILS: '/pdv/espace-sfr/{id}/caracteristiques',
  // CAMPAIGN
  CAMPAIGN_LIST: '/campagne',
  CAMPAIGN_INDEX: '/campagne/{cid}',
  CAMPAIGN_REFERENCES_INDEX: '/campagne/{cid}/reference',
  CAMPAIGN_REFERENCES_EDIT: '/campagne/{cid}/reference/modifier/{id}',
  CAMPAIGN_KIT_EDIT: '/campagne/{cid}/kit/modifier/{id}',
  CAMPAIGN_POSITIONS_VIEW: '/campagne/{cid}/emplacement',
  CAMPAIGN_POSITIONS_VIEW_INACTIVE: '/campagne/{cid}/emplacement/inactif',
  CAMPAIGN_HD_VIEW: '/campagne/{cid}/hd/{id}',
  CAMPAIGN_BAT_VIEW: '/campagne/{cid}/bat/{id}',
  // REFERENCE
  REFERENCE_LIST: '/reference',
  REFERENCE_EDIT: '/reference/modifier/{id}',
  // DASHBOARD
  DASHBOARD_VIDEO: '/dashboard/videos',
  // LOGISTIC
  ORDER_LINES_PUSH_TO_SEND: '/commande/push/to-send',
  ORDER_LINES_PUSH_SENT: '/commande/push/sent',
  ORDER_LINES_PUSH_DELETED: '/commande/push/deleted',
  ORDER_LINES_OPEN_TO_SEND: '/commande/open/to-send',
  ORDER_LINES_OPEN_SENT: '/commande/open/sent',
  ORDER_LINES_OPEN_DELETED: '/commande/open/deleted',
}

export const IMPUTATION_CODE: ImputationCode[] = [
  {
    value: 'O12EGS211002',
    name: 'Participation financière constructeurs',
    circuits: ['ESPACE_SFR', 'INTERNE'],
  },
  {
    value: 'O12EGS211111',
    name: 'Trade marketing',
    circuits: ['ESPACE_SFR', 'INTERNE'],
  },
  {
    value: 'O12EGS211100',
    name: 'PLV',
    circuits: ['ESPACE_SFR', 'INTERNE'],
  },
  {
    value: 'O12EGS211300',
    name: 'ILV',
    circuits: ['ESPACE_SFR', 'INTERNE'],
  },
  {
    value: 'O12EGS462003',
    name: 'Documents contractuels',
    circuits: ['ESPACE_SFR', 'INTERNE'],
  },
  {
    value: 'O12EGS211400',
    name: 'Animation / Théâtralisation',
    circuits: ['ESPACE_SFR', 'INTERNE'],
  },
  {
    value: 'O12EGS462002',
    name: 'PLV GE',
    circuits: ['GE'],
  },
  {
    value: 'O12EGS224026',
    name: 'ILV GE',
    circuits: ['GE'],
  },
  {
    value: 'O12EGS224023',
    name: 'Dispositif LCA tous canaux',
    circuits: ['ESPACE_SFR', 'INTERNE', 'GE'],
  },
  {
    value: 'O12EGS462008',
    name: 'Supports VAD',
    circuits: ['VAD'],
  },
]

export const ROLE: { [key in RoleKey]: Role } = {
  COMM_MANAGER: 'ROLE_COMM_MANAGER',
  FABRICATION_MANAGER: 'ROLE_FABRICATION_MANAGER',
  LOGISTICS_MANAGER: 'ROLE_LOGISTICS_MANAGER',
  CREATIVE_AGENCY: 'ROLE_CREATIVE_AGENCY',
  MANUFACTURER: 'ROLE_MANUFACTURER',
  LOGISTICIAN: 'ROLE_LOGISTICIAN',
  VIDEO_PROVIDER: 'ROLE_VIDEO_PROVIDER',
}
