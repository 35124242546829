var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isDisplay
    ? _c("div", { staticClass: "m-env" }, [_vm._v(_vm._s(_vm.pipeline))])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }