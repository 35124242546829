import ListSearch from './list-search'

import { API } from '~/utils/constants'

export default {
  orderPushReferences: {
    namespaced: true,
    state: () => ({
      ...ListSearch.state,
      endpoint: API.GET_ORDER_PUSH_REFERENCES,
    }),
    getters: { ...ListSearch.getters },
    actions: { ...ListSearch.actions },
    mutations: { ...ListSearch.mutations },
  },
  orderPushProvisional: {
    namespaced: true,
    state: () => ({
      ...ListSearch.state,
      endpoint: API.GET_ORDER_PUSH_PROVISIONAL,
    }),
    getters: { ...ListSearch.getters },
    actions: { ...ListSearch.actions },
    mutations: { ...ListSearch.mutations },
  },
  orderPushRetails: {
    namespaced: true,
    state: () => ({
      ...ListSearch.state,
      endpoint: API.GET_ORDER_PUSH_PDV,
    }),
    getters: { ...ListSearch.getters },
    actions: { ...ListSearch.actions },
    mutations: { ...ListSearch.mutations },
  },
  orderPushLinesSend: {
    namespaced: true,
    state: () => ({
      ...ListSearch.state,
      endpoint: API.GET_ORDER_PUSH_LINES_SEND,
    }),
    getters: { ...ListSearch.getters },
    actions: { ...ListSearch.actions },
    mutations: { ...ListSearch.mutations },
  },
  orderPushLinesSent: {
    namespaced: true,
    state: () => ({
      ...ListSearch.state,
      endpoint: API.GET_ORDER_PUSH_LINES_SENT,
    }),
    getters: { ...ListSearch.getters },
    actions: { ...ListSearch.actions },
    mutations: { ...ListSearch.mutations },
  },
  orderPushLinesDeleted: {
    namespaced: true,
    state: () => ({
      ...ListSearch.state,
      endpoint: API.GET_ORDER_PUSH_LINES_DELETED,
    }),
    getters: { ...ListSearch.getters },
    actions: { ...ListSearch.actions },
    mutations: { ...ListSearch.mutations },
  },
  orderPushDetails: {
    namespaced: true,
    state: () => ({
      ...ListSearch.state,
      endpoint: API.GET_ORDER_PUSH_LINES_SENT,
    }),
    getters: { ...ListSearch.getters },
    actions: { ...ListSearch.actions },
    mutations: { ...ListSearch.mutations },
  },
  orderPush: {
    namespaced: true,
    state: () => ({
      ...ListSearch.state,
      endpoint: API.GET_ORDER_PUSH_REFERENCES,
    }),
    getters: { ...ListSearch.getters },
    actions: { ...ListSearch.actions },
    mutations: { ...ListSearch.mutations },
  },
}
