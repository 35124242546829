import { CanceledError } from 'axios'

// Using this given store behavior requires to override the endpoint state property in your module
import api from '~/api/api-service'

const defaults = {
  page: 1,
  s: null,
  sp: null,
  sr: null,
  sort: {
    key: null,
    value: 'ASC',
    literal: null,
  },
  filters: {},
}

const state = {
  endpoint: '/api',
  method: 'GET',
  results: [],
  filters: {},
  sorting: {
    key: null,
    value: 'ASC',
    literal: null,
  },
  query: null,
  querySp: null,
  querySr: null,
  data: null,
  pagination: {
    total: 0,
    totalPages: 1,
    currentPage: 1,
  },
  loading: false,
  listenChange: true,
}

const mutations = {
  setResults(state, results): void {
    state.results = results
  },
  setQuery(state, query): void {
    state.query = query
  },
  setSearch(state, search): void {
    const { querySp, querySr } = search

    state.querySp = querySp !== undefined ? querySp : state.querySp
    state.querySr = querySr !== undefined ? querySr : state.querySr
  },
  setFilters(state, filters): void {
    state.filters = filters
  },
  setSorting(state, sorting): void {
    state.sorting = sorting
  },
  setPagination(state, pagination): void {
    state.pagination = pagination
  },
  setCurrentPage(state, page): void {
    state.pagination.currentPage = page
  },
  setEndpoint(state, endpoint): void {
    state.endpoint = endpoint
  },
  setMethod(state, method): void {
    state.method = method
  },
  setData(state, data): void {
    state.data = data
  },
  setLoading(state, loading): void {
    state.loading = loading
  },
  toggleLoading(state): void {
    state.loading = !state.loading
  },
  setListenChange(state, listen): void {
    state.listenChange = listen
  },
}

const actions = {
  async updateCurrentPage({ commit, dispatch }, page): Promise<void> {
    commit('setCurrentPage', page)
    await dispatch('updateResults', { resetPage: false, withPushState: true })
  },
  updateQuery({ commit }, query): void {
    commit('setQuery', query)
  },
  updateSearch({ commit }, search): void {
    commit('setSearch', search)
  },
  resetFilters({ state, commit, dispatch }, keys): void {
    let filters = { ...state.filters }

    if (keys === undefined || keys === null) {
      filters = {} // reset al
    } else {
      keys.forEach((key) => {
        if (filters[key]) {
          delete filters[key] // reset only this category
        }
      })
    }

    commit('setFilters', filters)
    dispatch('updateResults')
  },
  toggleFilter({ state, getters, commit, dispatch }, filter): void {
    const filters = { ...state.filters }

    if (filter.key in filters) {
      const idx = getters['selectedFilterIndex'](filter)

      if (idx !== -1) {
        filters[filter.key].splice(idx, 1)
        if (filters[filter.key].length === 0) {
          delete filters[filter.key]
        }
      } else {
        filters[filter.key].push(filter.value)
      }
    } else {
      filters[filter.key] = [filter.value]
    }

    commit('setFilters', filters)
    dispatch('updateResults')
  },
  toggleSorting({ state, commit, dispatch }, key): void {
    const sorting = { ...state.sorting }

    if (sorting.key !== key) {
      sorting.key = key
      sorting.value = 'ASC'
    } else {
      sorting.value = sorting.value === 'DESC' ? 'ASC' : 'DESC'
    }

    sorting.literal = `${(sorting.value === 'DESC' && '-') || ''}${sorting.key}`

    commit('setSorting', sorting)
    dispatch('updateResults')
  },
  updateParams({ commit }, params: any = {}): void {
    commit('setCurrentPage', params?.page || defaults.page)
    commit('setQuery', params?.s || defaults.s)
    commit('setSearch', {
      querySp: params?.sp || defaults.sp,
      querySr: params?.sr || defaults.sr,
    })
    commit('setSorting', params?.sort || defaults?.sort)
    commit('setFilters', params?.filters || defaults.filters)
  },
  async updateResults(
    { state, commit, getters },
    params: {
      resetPage: boolean
      withPushState: boolean
    } = {
      resetPage: true,
      withPushState: true,
    },
  ): Promise<void> {
    commit('setLoading', true)

    const requestParams = {
      page: !!params.resetPage ? 1 : state.pagination.currentPage,
      s: state.query,
      filters: state.filters,
      sort: state.sorting,
      sp: state.querySp,
      sr: state.querySr,
    }

    // TODO Check structuredClone @types availability + polyfill
    // @ts-ignore
    const apiSearchParams = structuredClone
      ? // @ts-ignore
        structuredClone(requestParams)
      : JSON.parse(JSON.stringify(requestParams))

    try {
      const data = await api.search(
        state.endpoint,
        state.method,
        apiSearchParams,
        state.data,
      )

      commit('setResults', data.results)
      commit('setPagination', {
        total: data.total,
        totalPages: data.nbPages,
        currentPage: data.page,
      })

      if (state.listenChange) {
        const url = new URL(document.location.toString())
        const urlSearchParams = new URLSearchParams()

        if (getters['active']) {
          for (const key in apiSearchParams) {
            if (!!apiSearchParams[key]) {
              const val = apiSearchParams[key]

              switch (key) {
                case 'filters':
                  if (Object.keys(val).length)
                    urlSearchParams.set(key, JSON.stringify(val))

                  break
                case 'sort':
                  if (val?.key) {
                    urlSearchParams.set(
                      key,
                      `${(val?.value === 'DESC' && '-') || ''}${val.key}`,
                    )
                  }

                  break
                default:
                  urlSearchParams.set(key, val)

                  break
              }
            }
          }
        }

        url.search = urlSearchParams.toString()
        if (!!params.withPushState) {
          history.pushState(apiSearchParams, '', url)
        }
      }

      commit('setLoading', false)
    } catch (error: unknown) {
      if (error instanceof CanceledError) {
        // Avoid log if the request in manually aborted
        return
      }

      commit('setLoading', false)

      // Keep throwing error to handle it with Sentry
      throw new Error(<any>error)
    }
  },
}

const getters = {
  results: (state) => {
    return state.results
  },
  query: (state) => {
    return state.query
  },
  querySr: (state) => {
    return state.querySr
  },
  querySp: (state) => {
    return state.querySp
  },
  pagination: (state) => {
    return state.pagination
  },
  currentPage: (state) => {
    return state.pagination.currentPage
  },
  total: (state) => {
    return state.pagination.total
  },
  sorting: (state) => {
    return state.sorting
  },
  selectedFilterIndex: (state) => (filter) => {
    if (filter.key in state.filters) {
      return state.filters[filter.key].indexOf(filter.value)
    }

    return -1
  },
  selectedFilterKey: (state) => (key) => {
    return state.filters[key]
  },
  totalFilters: (state) => {
    return Object.keys(state.filters).reduce((total, k) => {
      return total + state.filters[k].length
    }, 0)
  },
  loading: (state) => {
    return state.loading
  },
  listenChange: (state) => {
    return state.listenChange
  },
  active: (state) => {
    if (
      !!state.query ||
      !!state.querySp ||
      !!state.querySr ||
      !!state.sorting.literal ||
      +state.pagination.currentPage !== 1 ||
      !!Object.keys(state.filters).length
    ) {
      return true
    }

    return false
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
