










import { Component, Vue } from 'vue-property-decorator'
import { mapState, mapActions } from 'vuex'

const NotificationModal = () =>
  import('~/components/notification/NotificationModal.vue')

@Component({
  computed: {
    ...mapState('notifications', ['unread']),
  },
  methods: {
    ...mapActions('notifications', ['fetchUnreadCount']),
  },
})
export default class AppNotification extends Vue {
  // Vuex declaration for Vetur
  private unread!: number
  private fetchUnreadCount!: () => Promise<void>

  // Lifecycle
  public async created(): Promise<void> {
    await this.fetchUnreadCount()
  }

  // Methods
  private async openNotifications(): Promise<void> {
    this.$modal.show(
      NotificationModal,
      {},
      {
        clickToClose: true,
        scrollable: true,
        classes: 'm-modal',
      },
    )
  }
}
