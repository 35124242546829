import Vue from 'vue'
import Vuex from 'vuex'

import auth from './modules/auth'
import areas from './modules/areas'
import batFiles from './modules/bat-files'
import campaigns from './modules/campaigns'
import campaignPositions from './modules/campaign-positions'
import campaignReferences from './modules/campaign-references'
import dashboardPositions from './modules/dashboard-positions'
import companies from './modules/companies'
import formats from './modules/formats'
import hdFiles from './modules/hd-files'
import notifications from './modules/notifications'
import Orders from './modules/orders'
import orderPush from './modules/order-push'
import orderOpen from './modules/order-open'
import positions from './modules/positions'
import references from './modules/references'
import restocking from './modules/restocking'
import retailsEsp from './modules/retails-esp'
import retailsEspPositions from './modules/retails-esp-positions'
import retailsGe from './modules/retails-ge'
import retailsIns from './modules/retails-ins'
import retailsVad from './modules/retails-vad'
import targetings from './modules/targetings'
import users from './modules/users'
import videos from './modules/videos'

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    auth,
    areas,
    batFiles,
    campaigns,
    campaignPositions,
    campaignReferences,
    dashboardPositions,
    companies,
    formats,
    hdFiles,
    notifications,
    orders: Orders,
    ...orderPush,
    ...orderOpen,
    positions,
    references,
    restocking,
    retailsEsp,
    retailsEspPositions,
    retailsGe,
    retailsIns,
    retailsVad,
    targetings,
    users,
    videos,
  },
})

export default store
