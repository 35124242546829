import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators'

@Module({ namespaced: true, name: 'orders' })
export default class Orders extends VuexModule {
  // State
  public lines: LineProps[] = []

  // Getters
  public get lineById(): any {
    return (lineId: ID) => this.lines.find((line) => line.id === lineId)
  }

  public get orders(): LineOrderProps[] {
    return this.lines.reduce(
      (acc: LineOrderProps[], line) => [...acc, ...line.orders],
      [],
    )
  }

  public get totalOrders(): number {
    return this.lines.reduce((sum, line) => sum + line.orders.length, 0)
  }

  // Mutations
  @Mutation
  public addLine(line: LineProps): void {
    const lineIndex = this.lines.findIndex((l) => l.id === line.id)

    if (lineIndex > -1) {
      this.lines[lineIndex].orders = line.orders
    } else {
      this.lines.push(line)
    }
  }

  @Mutation
  public removeLine(line: LineProps): void {
    const lineIndex = this.lines.findIndex((l) => l.id === line.id)

    if (lineIndex > -1) {
      this.lines.splice(lineIndex, 1)
    }
  }

  @Mutation
  public addLineOrders(line: LineProps): void {
    const lineIndex = this.lines.findIndex((l) => l.id === line.id)

    if (lineIndex > -1) {
      const lineState = this.lines[lineIndex]
      const newOrders = line.orders.filter(
        (o) => lineState.orders.findIndex((s) => s.id === o.id) === -1,
      )

      this.lines[lineIndex].orders = [...lineState.orders, ...newOrders]
    } else {
      this.lines.push({ ...line })
    }
  }

  @Mutation
  public removeLineOrders(line: LineProps): void {
    const lineIndex = this.lines.findIndex((l) => l.id === line.id)

    if (lineIndex > -1) {
      const lineState = this.lines[lineIndex]
      const orderFiltered = lineState.orders.filter(
        (s) => line.orders.findIndex((o) => o.id === s.id) === -1,
      )

      this.lines[lineIndex].orders = [...orderFiltered]
    }
  }

  @Mutation
  public addOrder({
    id,
    order,
    ref,
    retail,
  }: {
    id: ID
    order: LineOrderProps
    ref: LineRefProps
    retail: LineRetailProps
  }): void {
    const lineIndex = this.lines.findIndex((l) => l.id === id)

    if (lineIndex > -1) {
      const lineState = this.lines[lineIndex]
      const orderExists =
        lineState.orders.findIndex((o) => o.id === order.id) > -1

      if (!orderExists) {
        this.lines[lineIndex].orders.push(order)
      }
    } else {
      this.lines.push({
        id,
        orders: [order],
        ref,
        retail,
      })
    }
  }

  @Mutation
  public updateOrder({ id, order }: { id: ID; order: LineOrderProps }): void {
    const lineIndex = this.lines.findIndex((l) => l.id === id)

    if (lineIndex > -1) {
      const lineState = this.lines[lineIndex]
      const orderExists =
        lineState.orders.findIndex((o) => o.id === order.id) > -1

      if (orderExists) {
        this.lines[lineIndex].orders[
          lineState.orders.findIndex((o) => o.id === order.id)
        ] = order
      }
    }
  }

  @Mutation
  public removeOrder({ id, order }: { id: ID; order: LineOrderProps }): void {
    const lineIndex = this.lines.findIndex((l) => l.id === id)

    if (lineIndex > -1) {
      const lineState = this.lines[lineIndex]
      const orderFiltered = lineState.orders.filter((o) => o.id !== order.id)

      this.lines[lineIndex].orders = [...orderFiltered]
    }
  }

  // Actions
  @Action
  public updateLine(payload: { line: LineProps; add: boolean }): void {
    if (payload.add) {
      this.context.commit('addLine', payload.line)
    } else {
      this.context.commit('removeLine', payload.line)
    }
  }

  @Action
  public updateLineOrders(payload: { line: LineProps; add: boolean }): void {
    if (payload.add) {
      this.context.commit('addLineOrders', payload.line)
    } else {
      this.context.commit('removeLineOrders', payload.line)
    }
  }

  @Action
  public changeOrder(payload: {
    id: ID
    order: LineOrderProps
    ref?: LineRefProps
    retail?: LineRetailProps
    action: 'add' | 'remove' | 'update'
  }): void {
    const { action, ...line } = payload

    switch (action) {
      case 'add': {
        this.context.commit('addOrder', line)
        break
      }
      case 'remove': {
        this.context.commit('removeOrder', line)
        break
      }
      case 'update':
      default: {
        this.context.commit('updateOrder', line)
        break
      }
    }
  }
}
