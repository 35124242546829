import { format, formatISO } from 'date-fns'

export const typeName = (type: Type): string => {
  if (type === 'object') return 'objet'

  if (type === 'video') return 'vidéo'

  return type
}

export const formatDatetime = (
  value: string | Date,
  formatStr: string = "dd/MM/yyyy HH'H'mm",
): string => {
  try {
    return !value ? '' : format(new Date(value), formatStr)
  } catch {
    return value.toString()
  }
}

export const formatDate = (
  value: string | Date,
  formatStr: string = 'dd/MM/yyyy',
): string => formatDatetime(value, formatStr)

export const formatHour = (
  value: string,
  formatStr: string = "HH'H'mm",
): string => formatDatetime(value, formatStr)

export const formatDateSql = (value: string): string =>
  formatDate(value, 'yyyy-MM-dd')

export const extractDate = (value: string | Date): string => {
  if (!(value instanceof Date)) {
    value = new Date(value)
  }

  return formatISO(value, { representation: 'date' })
}

export const displayedName = (f: string, l: string): string => {
  if (l) {
    if (f.split(/-|\s/).length > 1) {
      const fs: string[] = f.split(/-|\s/)
      const separators = f.match(/-|\s/g) as string[]

      if (fs.length !== separators.length + 1) {
        return `${fs[0].slice(0, 1)?.toUpperCase()}.  ${l}`
      }

      return `${fs
        .map(
          (name, i): string =>
            `${name.slice(0, 1).toUpperCase()}.${separators[i] ?? ''}`,
        )
        .join('')} ${l}`
    }

    return `${f.slice(0, 1)?.toUpperCase()}. ${l}`
  }

  return f
}

export const findStatus = (
  status: string,
  type: 'global' | 'step' | 'name' = 'name',
): string => {
  const [globalStatus, stepStatus, namedStatus] = status.split('|')

  if (type === 'global') {
    return globalStatus
  }

  if (type === 'step') {
    return stepStatus
  }

  return namedStatus
}

export const stepStatus = (
  status: Status | string,
  type: 'reference' | 'hd' | 'bat',
): number => {
  const step: string = findStatus(
    status,
    type === 'reference' ? 'global' : 'step',
  )
  const s: string[] = step.split('-')

  return parseInt(s.reverse()[0], 10)
}

export const initials = (firstName: string, lastName: string): string => {
  return `${firstName.charAt(0).toUpperCase()}${lastName
    .charAt(0)
    .toUpperCase()}`
}

export const defineButtonStateForHd = (
  type: 'accept' | 'deny',
  hdStatus: string,
  referenceStatus: string,
  role: Role = 'ROLE_COMM_MANAGER',
): ButtonState => {
  const status: string = findStatus(hdStatus, 'global')
  const hdStep: number = stepStatus(hdStatus, 'hd')
  const referenceStep: number = stepStatus(referenceStatus || '', 'reference')

  let display: boolean = true
  let active: boolean = false
  let inactive: boolean = false
  let disabled: boolean = true

  if (role === 'ROLE_MANUFACTURER' && type === 'accept') {
    display = false
  } else if (referenceStep > 8) {
    active = type === 'accept'
    inactive = !active
  } else if (role === 'ROLE_COMM_MANAGER') {
    if (hdStep > 1) {
      disabled = false
    }

    if (hdStep === 3 || hdStep === 4) {
      // Validated by comm on step 2
      active = type === 'accept'
      inactive = !active
    }

    if (hdStep === 4) {
      disabled = true
    }

    if (hdStep === 5) {
      if (status === 'REFUSED_BY_COMM') {
        active = type === 'deny'
        inactive = !inactive
      } else {
        active = type === 'accept'
        inactive = !active
        disabled = true
      }
    }
  } else if (role === 'ROLE_FABRICATION_MANAGER') {
    if (hdStep > 2) {
      disabled = false
    }

    if (hdStep === 4) {
      active = type === 'accept'
      inactive = !active
    }

    if (hdStep === 5) {
      if (status === 'REFUSED_BY_FAB') {
        active = type === 'deny'
        inactive = !inactive
      } else {
        disabled = true

        if (status === 'REFUSED_BY_MANUFACTURER') {
          active = type === 'deny'
          inactive = !inactive
        }
      }
    }
  } else if (role === 'ROLE_MANUFACTURER') {
    if (type === 'accept') {
      display = false
    } else {
      if (hdStep > 3) {
        disabled = false
      }

      if (hdStep === 5) {
        if (status === 'REFUSED_BY_MANUFACTURER') {
          active = true
        } else {
          disabled = true
        }
      }
    }
  }

  return {
    display,
    active,
    inactive,
    disabled,
  }
}

export const defineButtonStateForBat = (
  type: 'accept' | 'deny',
  batStatus: string,
  referenceStatus: string,
  role: Role = 'ROLE_COMM_MANAGER',
): ButtonState => {
  const status: string = findStatus(batStatus, 'global')
  const batStep: number = stepStatus(batStatus, 'bat')
  const referenceStep: number = stepStatus(referenceStatus || '', 'reference')

  let active: boolean = false
  let inactive: boolean = false
  let disabled: boolean = true
  let display: boolean = true

  if (referenceStep > 11) {
    active = type === 'accept'
    inactive = !active
  } else if (role === 'ROLE_COMM_MANAGER') {
    if (batStep > 2) {
      disabled = false
    }

    if (batStep === 4) {
      active = type === 'accept'
      inactive = !active
    }

    if (batStep === 5) {
      if (status === 'REFUSED_BY_COMM') {
        active = type === 'deny'
        inactive = !active
      } else {
        disabled = true
      }
    }
  } else if (role === 'ROLE_FABRICATION_MANAGER') {
    if (batStep > 1) {
      disabled = false
    }

    if (batStep === 3 || batStep === 4) {
      // Validated by fab on step 2
      active = type === 'accept'
      inactive = !active
    }

    if (batStep === 4) {
      disabled = true
    }

    if (batStep === 5) {
      if (status === 'REFUSED_BY_FAB') {
        active = type === 'deny'
        inactive = !active
      } else {
        active = type === 'accept'
        inactive = !active
        disabled = true
      }
    }
  } else if (role === 'ROLE_MANUFACTURER') {
    display = false
  }

  return {
    display,
    active,
    inactive,
    disabled,
  }
}

export const defineButtonState = (
  entity: 'hd' | 'bat',
  type: 'accept' | 'deny',
  status: string,
  referenceStatus: string,
  role: Role = 'ROLE_COMM_MANAGER',
): ButtonState => {
  return entity === 'hd'
    ? defineButtonStateForHd(type, status, referenceStatus, role)
    : defineButtonStateForBat(type, status, referenceStatus, role)
}

export const formatFileActionText = (
  entity: 'hd' | 'bat',
  action: string,
  index: number = 0,
): string => {
  switch (action) {
    case 'UPLOAD_BAT':
    case 'UPLOAD_HD': {
      return index === 0
        ? `${entity.toUpperCase()} uploadé`
        : `Nouveau ${entity.toUpperCase()} uploadé`
    }
    case 'VALIDATE_BY_COMM':
    case 'VALIDATE_BY_FAB': {
      return `${entity.toUpperCase()} validé`
    }
    case 'REFUSE_BY_COMM':
    case 'REFUSE_BY_FAB':
    case 'REFUSE_BY_MANUFACTURER': {
      return `${entity.toUpperCase()} refusé`
    }
  }

  return ''
}

export const formatValidationText = (validation): string => {
  let role: string = ''

  switch (validation.createdBy.role as Role) {
    case 'ROLE_COMM_MANAGER': {
      role = 'Responsable comm'
      break
    }
    case 'ROLE_FABRICATION_MANAGER': {
      role = 'Responsable fab'
      break
    }
    case 'ROLE_CREATIVE_AGENCY':
    case 'ROLE_MANUFACTURER': {
      role = validation.createdBy.company?.name || ''
      break
    }
  }

  return `le ${formatDatetime(validation.created)} par ${
    validation.createdBy.firstName
  } ${validation.createdBy.lastName}${role ? ` (${role})` : ''}`
}
